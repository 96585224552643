import React, { useState, useEffect } from 'react';

let apiUrl = process.env.REACT_APP_APIPATH_MOBILE;
let urlApp = "http://localhost:3001"

const Lab = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [businessLaboratoryRoom, setBusinessLaboratoryRoom] = useState([]);
  const [checkedRooms, setCheckedRooms] = useState([]);

  useEffect(() => {
    //get business laboratory room by config ID set eg. ID=1,2,3
    getBusinessLaboratoryRoom();

  }, []);

  //get business lab room by ID eg. RoomID=1,2,3
  const getBusinessLaboratoryRoom = async () => {
    try {
      const formdata = new FormData();
      const requestOptions = {
        method: "POST",
        redirect: "follow",
      };
     
      const response = await fetch(
        `${apiUrl}/webaccess/getbusinesslaboratoryroom`,
        requestOptions
      );
      const data = await response.json();
      if (data.status === 200) {
        setBusinessLaboratoryRoom(data.data);
      };
    } catch (error) {
      console.error("Error fetching request list:", error);
    }
  };

  const handleCheckboxChange = (index) => {
    const updatedCheckedRooms = [...checkedRooms];
    updatedCheckedRooms[index] = !updatedCheckedRooms[index];
    setCheckedRooms(updatedCheckedRooms);
  };

  const goToBookedLab = () => {
    // Filter out the checked rooms and get their IDs
    const checkedRoomIds = businessLaboratoryRoom
      .filter((room, index) => checkedRooms[index])
      .map((room) => room.id);
    // Redirect to another page
    window.location.href = urlApp+'?RoomID='+checkedRoomIds;
  };

  return (
    <div>
    <div style={{textAlign: 'center', margin: '20px 0px'}}>
        <img src="../images/logo-black.png" alt="Logo" style={{width: '150px'}}/>
      </div>
      <div style={{clear: 'both'}}></div>
    <div style={{ margin: 'auto', marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <fieldset style={{border: '1px solid #ccc', width: '400px'}}>
      <legend>Business Laboratory Room</legend>
        {businessLaboratoryRoom !== null ? (
          businessLaboratoryRoom.map((room, index) => (
            <div key={room.id}>
            <input
              type="checkbox"
              checked={checkedRooms[index] || false}
              onChange={() => handleCheckboxChange(index)}
            />
            <label>{room.bl_room_name}</label>
          </div>
          ))
        ) : (
          <p>businessLaboratoryRoom is null</p>
        )}      
        <div style={{float: 'right'}}>
          <button style={{backgroundColor: '#e7e7e7', padding: '10px 20px', border: '1px solid #ccc'}} onClick={goToBookedLab}>Submit</button>
        </div>
      </fieldset>        
    </div>
    </div>
  );
};

export default Lab;