import React, { useState, useEffect } from 'react';

const DigitalClock = () => {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId); // Cleanup the interval on component unmount
  }, []); // Empty dependency array to run the effect only once on mount

  const formattedTime = currentTime.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });
  return (
    <div style={{ textAlign: 'right', float: 'right', fontSize: '18px', color: '#76323f', fontWeight: 'bold', paddingRight: '5px', borderRadius: '10px' }}>
      {formattedTime}
    </div>
  );
};

export default DigitalClock;
