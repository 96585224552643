import React, { useState, useEffect, useRef } from 'react';
import { DayPilotCalendar, DayPilot } from "@daypilot/daypilot-lite-react";
import moment from 'moment';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import DigitalClock from './../components/Watches';

let apiUrl = process.env.REACT_APP_APIPATH_MOBILE;

const Calendar = () => {
  const [currentTime, setCurrentTime] = useState(moment().format('YYYY-MM-DD HH:mm:ss'));
  const [dataList, setDataList] = useState([]);
  const [businessLaboratoryRoom, setBusinessLaboratoryRoom] = useState([]);
  const [events, setEvents] = useState([]);
  const [loadedImages, setLoadedImages] = useState([]);

  // useEffect to initialize columns
  useEffect(() => {
    //get business lab reservation with current date
    dataRequestBusinessLabReservationList();
    //get business laboratory room by config ID set eg. ID=1,2,3
    getBusinessLaboratoryRoom();
    //get events from api
    getEvents();

  }, []);

  // State for viewType
  const [config, setConfig] = useState({
    viewType: "Resources",
    businessBeginsHour: 8,
    businessEndsHour: 22,
    // // startDate: '2024-01-05T08:00:00', //
    //timeRangeSelectedHandling: 'Enabled',
    // timeRange: {
    //   start: '08:00', // Start time
    //   end: '21:00',   // End time
    // },

    onBeforeEventRender: args => {
      const event = args.data;
      const image = event.image;

      // Add an image element to the event
      if (image) {
        args.data.html = `
        <div style="display: flex; align-items: flex-start;">      
        <div style="flex-grow: 1;">
          <div style="color: #000; text-align: left;"><b>${args.data.text}</b></div>
          <div style="text-align: left; margin-top:">${args.data.des}</div>
        </div>
        <div style="margin-left: auto; margin-right: 5px;">
          <img src="${image}" alt="Event Image" style="border-radius: 50%; overflow: hidden; width: 30px; height: 30px; border: 2px solid #ddd;">
        </div>
      </div>
      `;
      }        
    },
  });

  const getParams = () => {
    let queryParams = new URLSearchParams(window.location.search);

    // Get the value of the 'ID' parameter
    let idParam = queryParams.get('RoomID');
    // Check if 'ID' parameter exists
    if (idParam) {
      return idParam;
    }else{
      return '1,2';
    }
    // If 'ID' parameter does not exist or is empty
    return [];
  };

  //get params from url eg. RoomID=1,2,3
  const ids = getParams();
  const roomIdsArray = ids.split(',').map(roomId => parseInt(roomId, 10));

  // Display current time
  const now = moment(); // Get the current  
  const currentTimeEvent = {
    text: 'Current Time',
    start: currentTime,
    end: currentTime,
    barColor: '#f00',
    resource: 'R' + roomIdsArray[0],
  };

  //set up event format
  const formattedEvents = dataList.map((event) => {
    const start = moment(new Date(event.start_booking_time)).format('YYYY-MM-DD HH:mm:ss');
    const end = moment(new Date(event.end_booking_time)).format('YYYY-MM-DD HH:mm:ss');
    
    let barColor;
    switch (event.bl_room_id) {
      case 1:
          barColor = "#76323f";
          break;
      case 2:
          barColor = "#76323f";
          break;
      case 3:
          barColor = "#76323f";
          break;
      case 4:
          barColor = "#76323f";
          break;
      case 5:
          barColor = "#76323f";
          break;
      default:
          barColor = "#76323f"; // Default color for other rooms
          break;
    }
    return {
      text: `${event.LastName} ${event.FirstName}`,
      start: start,
      end: end,
      barColor: barColor,
      resource: "R" + event.bl_room_id,
      image: `https://imagedelivery.net/22Use1D1z0CeXnTc4TRU3A/${event.Photo}/thumbnail`,
      des: `${event.purpose_of_booking_detail}`,
      backColor: "#d9b628",
    };
  });

  //get Business Lab Reservation
  const formattedRooms = businessLaboratoryRoom.map((room) => ({
    name: room.bl_room_name , id: "R"+room.id,
  }));
  
  // get data from API
  const dataRequestBusinessLabReservationList = async () => {
    try {
      const formdata = new FormData(); 
      formdata.append("bookingDate", moment(new Date()).format("YYYY-MM-DD"));
      formdata.append("businessLabRoomId", getParams());
      const requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };
      
      const response = await fetch(
        `${apiUrl}/webaccess/getbookedbusinesslabreservation`,
        requestOptions
      );
      const data = await response.json();
      if (data.status === 200) {
        setDataList(data.data);
      };
    } catch (error) {
      console.error("Error fetching request list:", error);
    }
  };

  //get business lab room by ID eg. RoomID=1,2,3
  const getBusinessLaboratoryRoom = async () => {
    try {
      const formdata = new FormData();
      formdata.append("businessLabRoomId", getParams());
      const requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };
     
      const response = await fetch(
        `${apiUrl}/webaccess/getbusinesslaboratoryroom`,
        requestOptions
      );
      const data = await response.json();
      if (data.status === 200) {
        setBusinessLaboratoryRoom(data.data);
      };
    } catch (error) {
      console.error("Error fetching request list:", error);
    }
  };

  //get business lab room by ID eg. RoomID=1,2,3
  const getEvents = async () => {
    try {
      const requestOptions = {
        method: "POST",
        redirect: "follow",
      };     
      const response = await fetch(
        `${apiUrl}/webaccess/getevents`,
        requestOptions
      );      
      const data = await response.json();
      //const dataLists = data.slice(0, 3);
      setEvents(data);
    } catch (error) {
      console.error("Error fetching request list:", error);
    }
  };

  const intervalInMilliseconds = 5 * 60 * 1000; // 30 minutes
  window.setTimeout(function () {
    window.location.reload();
  }, intervalInMilliseconds);

  // Combine the current time event with your existing events
  const formattedEventsWithCurrentTime = [...formattedEvents];
  
  const settings = {
    dots: true,
    infinite: true,
    speed: 2500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000, // Adjust the autoplay speed in milliseconds
  };

  const handleImageLoad = (itemId) => {
    setLoadedImages((prevLoadedImages) => [...prevLoadedImages, itemId]);
  };

  return (
    <>
      <div style={{ textAlign: 'left', margin: '5px 5px 0px 10px' }}>
        <div style={{ width: '100%', maxWidth: '1400px', margin: '0 auto', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center', maxWidth: '400px', fontSize: '14px', textAlign: 'left' }}>
            <span style={{marginRight: '5px'}}>📅</span> {moment(new Date()).format("DD MMMM YYYY")}
          </div>
          <div style={{ maxWidth: '400px', textAlign: 'center' }}>
            <img src="../images/logo-black.png" alt="CamEd Logo" style={{ maxWidth: '180px' }} />
          </div>
          <div style={{ display: 'flex', alignItems: 'center', maxWidth: '400px', fontSize: '12px', textAlign: 'right' }}>
            {/* <span>⌚</span> */}
            <DigitalClock />
          </div>
        </div>
      </div>
      <div style={{clear: 'both'}}></div>
        <div className='container'>
          <Slider {...settings}>
            {events.map((item) => (
              <div className="image-wrap" key={item.id}>
                <div className="responsive-image-container">
                  {loadedImages.includes(item.id) ? null : (                    
                    <div class="hollow-dots-spinner">
                      <div class="dot"></div>
                      <div class="dot"></div>
                      <div class="dot"></div>
                    </div>
                  )}
                  <img
                    src={item.image?.medium_large?.url ? item.image.medium_large.url : "../images/event1.jpg"}
                    alt="Event Image"
                    onLoad={() => handleImageLoad(item.id)}
                    onError={() => handleImageLoad(item.id)}
                    style={{ display: loadedImages.includes(item.id) ? 'block' : 'none' }}
                  />
                </div>
              </div>
            ))}
          </Slider>
        </div>      

        <div style={{ width: '100%', display: 'flex', marginTop: '10px' }}>
          {/* Responsive Calendar */}
          <div className="calendar-container" style={{ float: 'left', width: '100%', marginBottom: '20px', overflowX: 'hidden'}}>
            <DayPilotCalendar
              {...config}
              // ref={currentTimeRef}
              columns={formattedRooms}
              events={formattedEventsWithCurrentTime}
              style={{ color: '#f00', fontSize: '12px', overflow: 'hidden',  height: '2000px' }}
              className="my-calendar"
              cellHeight={25} 
            />
          </div>
        </div>     
        <div style={{marginBottom: '0px', marginTop: '-20px'}}>
          <div className="time-note">
            <div style={{float: 'left'}}>
              <fieldset>
                <legend>Note: </legend>
                <div className="time-block">
                  <div className="time-line">&nbsp;</div>
                  <div className="time-des">Booked Time</div>
                  <div className="time-line-white">&nbsp;</div>
                  <div className="time-des">Available Time</div>
                </div>
              </fieldset>
            </div>
            <div style={{float: 'right', paddingBottom: '30px'}}>
              <div className="booked-schedule"><span className="redtext">*</span> Students are required to reserve the laboratory room</div>
              <div className="power-by">Powered by: <span class="text-bold">IT Department</span></div>
            </div>
            <div style={{clear: 'both'}}></div>
          </div>
        </div>
      <div>
      </div>
    </>
  );  
}

export default Calendar;